export const mediaPress = [
  {
    name: 'Logo exame',
    href: '',
    imageURL: 'Others/Medias/midias-exame-branca-2x-180x30_aphecd',
    idealWidth: { xs: '110px', sm: '120px', md: '180px' },
    idealHeight: { xs: '18px', sm: '20px', md: '30px' },
    sizes: '(max-width: 768px) 100vw, (max-width: 1200px) 50vw',
    mt: '7px',
  },
  {
    name: 'Logo folha de são paulo',
    href: '',
    imageURL: 'Others/Medias/midias-folhasp-branca-2x-180x30_algj5o',
    idealWidth: { xs: '110px', md: '180px' },
    idealHeight: { xs: '18px', sm: '32px', md: '30px' },
    sizes: '(max-width: 768px) 33vw, (max-width: 1200px) 50vw',
    mt: '10px',
  },
  {
    name: 'Logo bandnews',
    href: '',
    imageURL: 'Others/Medias/midias-bandnews-2x-branca-180x30_sbixck',
    idealWidth: { xs: '110px', sm: '180px' },
    idealHeight: { xs: '18px', sm: '30px' },
    sizes: '(max-width: 768px) 33vw, (max-width: 1200px) 50vw',
    mt: '6px',
  },
  {
    name: 'RA1000',
    href: '',
    imageURL: '/Others/Icons/Payments/certificado_RA_Branco_uqwgfq',
    idealWidth: { xs: '70px', sm: '88px', md: '106px' },
    idealHeight: { xs: '32px', sm: '42px', md: '53px' },
    sizes: '(max-width: 768px) 100vw, (max-width: 1200px) 50vw',
  },
];
